import React from 'react';
import TitleBanner from '../general/titleBanner';
import Input from '../general/input';
import Dropdown from '../general/dropdown';


import PermissionsIllustration1 from 'assets/permissions-illustration1.svg'
import PermissionsIllustration2 from 'assets/permissions-illustration2.svg'

import './permissions.scss';

const Permissions = (props) => {
  return (
    <div>
      <section class="section">
        <TitleBanner
          title="Users and Permissions"
          leftImg={PermissionsIllustration1}
          rightImg={PermissionsIllustration2}
          leftTop="-8px"
          rightTop="-40px"
        />
      </section>

      <section class="section">
        <div class="columns">
          <div class="column is-8">
            <div class="card permissions-card">
              <div class="card-content">
                <p className="has-text-weight-bold">Members</p>
                <div className="level">
                  <div className="level-left">
                    <p className="is-block">radu@mail.com</p>
                    <p className="is-block is-size-7">Mail confirmed</p>
                  </div>
                  <div className="level-right">
                    <div className="buttons">
                      <button className="button is-button">
                        Admin
                      </button>
                      <button className="button is-button">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div className="level">
                  <div className="level-left">
                    <p className="is-block">radu@mail.com</p>
                    <p className="is-block is-size-7">Mail confirmed</p>
                  </div>
                  <div className="level-right">
                    <div className="buttons">
                      <button className="button is-button">
                        Admin
                      </button>
                      <button className="button is-button">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-content">
                <p className="has-text-weight-bold">Create member account</p>
                <p>This will not send any details of the created account yet. You will have the option to manage the account after it has been created</p>
                <Input
                  hasAddon={true}
                  addonContent={<Dropdown name="Admin" />}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Permissions;
