import React from 'react';
// import axios from 'axios';
// import moment from 'moment';

import Input from '../general/input';
import ActionBar from '../general/actionBar';

const PetNew = (props) => {

  return (
    <div>
      <ActionBar
        {...props}
        back={true}
        rightSide={
          <div className="buttons">
            <button className="button is-text is-small" onClick={() => props.history.goBack()}>Cancel</button>
            <button className="button is-success is-small">Add pet</button>
          </div>
        }
      />

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">Add a new pet</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-9">
            <div className="columns is-multiline">
              <div className="column is-4">
                <Input label="Name" placeholder="Radu" />
              </div>
              <div className="column is-4">
                <Input label="Species" placeholder="Dog" />
              </div>
              <div className="column is-4">
                <Input label="Breed" placeholder="Beagle" />
              </div>
              <div className="column is-4">
                <Input label="Hair" placeholder="Short" />
              </div>
              <div className="column is-4">
                <Input label="Hair color" placeholder="Brown and white" />
              </div>
              <div className="column is-4">
                <Input label="Date of birth" placeholder="" />
              </div>
              <div className="column is-4">
                <Input label="Gender" placeholder="Male" />
              </div>
              <div className="column is-4">
                <Input label="Spayed/Neutered" placeholder="Yes" />
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default PetNew;
