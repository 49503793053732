import React from 'react';
import Input from '../general/input'

const ProfileData = (props) => {

  const user = props.user;

  console.log(props.user)
  
  return (
    <div className="columns is-multiline">
      <div className="column is-4">
        <Input id="first_name" label="First Name" value={user.first_name} />
      </div>
      <div className="column is-4">
        <Input id="last_name" label="Last Name" value={user.last_name}/>
      </div>
      <div className="column is-4">
        <Input id="email" label="Email" value={user.email}/>
      </div>

      <div className="column is-12">
        <p className="has-text-weight-bold">Address</p>
        <p className="is-size-7">This is essential information for someone who may find your lost pet</p>
      </div>
      <div className="column is-6">
        <Input id="address1" label="Address 1" value={user.address1}/>
      </div>
      <div className="column is-6">
        <Input id="address2" label="Address 2" value={user.address2}/>
      </div>
      <div className="column is-2">
        <Input id="apt" label="Apt." value={user.apt}/>
      </div>
      <div className="column is-2">
        <Input id="city" label="City" value={user.city}/>
      </div>
      <div className="column is-2">
        <Input id="state" label="State" value={user.state}/>
      </div>
      <div className="column is-2">
        <Input id="county" label="County" value={user.county}/>
      </div>
      <div className="column is-2">
        <Input id="zip" label="ZIP code" value={user.zip}/>
      </div>

      <div className="column is-12">
        <p className="has-text-weight-bold">Phone</p>
        <p className="is-size-7">This is essential information for someone who may find your lost pet</p>
      </div>
      <div className="column is-4">
        <Input id="phone" label="Phone" value={user.phone}/>
      </div>
      <div className="column is-4">
        <Input id="mobile" label="Mobile" value={user.mobile}/>
      </div>
    </div>
  )
}

export default ProfileData;
