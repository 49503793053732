import React from 'react';
import { Link } from 'react-router-dom';
import WelcomeIllustration from 'assets/welcome-illustration.svg'
import FAQIllustration from 'assets/faq-illustration.svg';
import Pets from '../pets';


import Faqs from './faqs';
import Tag from '../general/tag';

const DashboardLogged = (props) => {

  return (
    <div>
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="is-size-4 has-text-weight-bold">Welcome back!</p>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-3">
            <img src={WelcomeIllustration} alt="Person greeting dog" />
          </div>
          <div className="column is-9">
            <div className="content">
              <p>You have pending tasks!</p>
              <Tag type="warning">
                <p>Doggo’s rabies certificate is going to expire soon. Click here to renew it</p>
              </Tag>

              <Tag type="danger">
                <p>Doggo’s license expired</p>
              </Tag>
            </div>
          </div>
        </div>
      </section>

      <Pets />

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="is-size-4 has-text-weight-bold">Community and announcements</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-6">
            <div className="columns is-multiline">
              <div className="column is-12">
                <div className="card">
                  <div className="card-content">
                    <p className="has-text-weight-bold">Licensing period extended</p>
                    <p>Due to the recent developments, we are extending the period when you can license your pet with 3 months.</p>
                    {/* <a>read more</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="columns is-multiline">
              <div className="column is-6">
                <Link className="card has-background-dark" to="/user/restaurants">
                  <div className="card-content has-text-centered">
                    <p className="has-text-white">Pet friendly restaurants</p>
                  </div>
                </Link>
              </div>

              <div className="column is-6">
                <Link className="card has-background-dark" to="/user/hotels">
                  <div className="card-content has-text-centered">
                    <p className="has-text-white">Pet friendly hotels</p>
                  </div>
                </Link>
              </div>

              <div className="column is-6">
                <Link className="card has-background-dark" to="/user/services">
                  <div className="card-content has-text-centered">
                    <p className="has-text-white">Pet friendly services</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DashboardLogged;
