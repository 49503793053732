import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Tag from '../general/tag';
import Input from '../general/input';
import Select from '../general/select';
import Table from '../general/table';
import ActionBar from '../general/actionBar';

const PetSingle = (props) => {

  const [pet, setPet] = useState();
  const [missing, setMissing] = useState(false);

  useEffect(() => {
    if (props.pet) {
      setPet(props.pet);
    } else {
      axios.get(process.env.REACT_APP_API_URL+'pets/'+props.match.params.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((res) => {
        console.log(res.data.data);
        setPet(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [])

  const selectRow = (e) => {
    console.log(e.target);
    e.target.closest('tr').classList.toggle('is-selected');
  }

  const changeMissing = (e) => {
    if (e.target.value === "Yes") {
      setMissing(true)
    }  else setMissing(false);
  }

  return (
    <div>
      <ActionBar
        {...props}
        back={true}
        rightSide={
          <div className="buttons">
            <button className="button is-text is-small" onClick={() => props.history.goBack()}>Cancel</button>
            <button className="button is-success is-small">Save</button>
          </div>
        }
      />

      {missing ? <section className="section">
        <div className="columns">
          <div className="column is-12">
            <div className="card has-background-danger">
              <div className="card-content content">
                <p className="has-text-weight-bold has-text-white">{pet?pet.name:""} is missing</p>
                <p className="has-text-white">To help you find him more easily, we generated a Missing Poster that can be shared online or printed. </p>
                <button className="button is-white is-small">Download Poster</button>
              </div>
            </div>
          </div>
        </div>
      </section> : ""}

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">{pet?pet.name:""}'s information</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-3">
            <img src={pet?"https://api.roamingtails.co/uploads/profiles/"+pet.image:""} />
          </div>
          <div className="column is-9">
            <div className="columns is-multiline">
              <div className="column is-4">
                <Input label="Name" placeholder="Shorty" value={pet?pet.name:""} />
              </div>
              <div className="column is-4">
                <Input label="Species" placeholder="Dog" value={pet?pet.type:""} />
              </div>
              <div className="column is-4">
                <Input label="Breed" placeholder="Beagle" value={pet?pet.breed:""} />
              </div>
              <div className="column is-4">
                <Input label="Hair" placeholder="Short" value={pet?pet.hair:""} />
              </div>
              <div className="column is-4">
                <Input label="Hair color" placeholder="Brown and white" value={pet?pet.color:""} />
              </div>
              <div className="column is-4">
                <Input label="Date of birth" placeholder="" value={pet?moment(pet.dob).format("MMM Do YY"):""} />
              </div>
              <div className="column is-4">
                <Input label="Gender" placeholder="Male" value={pet?pet.gender:""} />
              </div>
              <div className="column is-4">
                <Input label="Spayed/Neutered" placeholder="Yes" />
              </div>
              <div className="column is-4">
                <div>
                  <label className="label">Missing</label>
                  <div className="field">
                    <div className="control">
                      <div className={missing?"select is-fullwidth is-danger":"select is-fullwidth"}>
                        <select onChange={(e) => changeMissing(e)}>
                          <option>No</option>
                          <option value="Yes">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="level">
          <div className="level-left">
            <p className="has-text-weight-bold is-size-4">{pet?pet.name:""}'s licenses</p>
          </div>
          <div className="level-right">
            {/* <button className="button is-dark is-small">+ Add new license</button> */}
          </div>
        </div>
        <Table thTitles={['License #', "License Date", "Expiry Date", "Status"]}>
          <tr>
            <td nowrap="true"><input type="checkbox" onChange={(e) => selectRow(e)}></input></td>
            <td nowrap="true"><p>{pet ? pet.tag_number : "No license"}</p></td>
            <td nowrap="true"><p>{pet ? moment(pet.created_at).format("MMM Do YY") : ""}</p></td>
            <td nowrap="true"><p>{pet ? moment(pet.created_at).add(1, 'y').format("MMM Do YYYY") : ""}</p></td>
            <td nowrap="true">{pet && pet.tag_number ? <p className="has-text-success">Active</p> : <p className="has-text-danger">Inactive</p>}</td>
          </tr>
        </Table>
      </section>

      <section className="section">
        <div className="level">
          <div className="level-left">
            <p className="has-text-weight-bold is-size-4">{pet?pet.name:""}'s rabies certificates</p>
          </div>
          <div className="level-right">
            {/* <button className="button is-dark is-small">+ Add new certificate</button> */}
          </div>
        </div>
        <Table thTitles={['License #', "License Date", "Expiry Date", "Status"]}>
          <tr>
            <td nowrap="true"><p>No entries</p></td>
          </tr>
        </Table>
      </section>

      <section className="section">
        <div className="columns">
            <div className="column is-12">
              <p className="is-size-4 has-text-weight-bold">Pet administration</p>
            </div>
          </div>
        <div className="columns is-multiline">

          <div className="column is-6">
            <Link className="card" to="/dashboard/rabies">
              <div className="card-content has-text-centered">
                I want to renew this pet's license
              </div>
            </Link>
          </div>

          <div className="column is-6">
            <Link className="card" to="/dashboard/rabies">
              <div className="card-content has-text-centered">
                Both my pet and I relocated
              </div>
            </Link>
          </div>

          <div className="column is-6">
            <Link className="card" to="/dashboard/rabies">
              <div className="card-content has-text-centered">
                I don't have this pet anymore
              </div>
            </Link>
          </div>

          <div className="column is-6">
            <Link className="card" to="/dashboard/rabies">
              <div className="card-content has-text-centered">
                I want to transfer this pet to another owner
              </div>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PetSingle;
