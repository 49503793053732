import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import ActionBar from '../general/actionBar';
import Table from '../general/table';

import ProfileData from '../profile/profileData';
import ProfileImage from '../profile/profileImage';

const OwnerSingle = (props) => {

  const [owner, setOwner] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'user/'+props.match.params.id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      setOwner(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])

  return (
    <div>
      <ActionBar
        {...props}
        buttonLeft={<button className="button is-text is-small">Cancel</button>}
        buttonRight={<button className="button is-success is-small">Save</button>}
        back={true}
      />
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">{owner?owner.first_name:"Name"}'s information</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-3">
            <ProfileImage image={owner?owner.picture:""} />
          </div>
          <div className="column is-9">
            <ProfileData user={owner?owner:""} />
          </div>
        </div>
      </section>

      <section className="section">
        <Table thTitles={["Name", "Type", "Breed",  "Color", "Tag #", "Date of birth", "Email"]}>
          {owner?owner.pets.map((pet, index) => {
              return (
                <tr key={index}>
                  <td nowrap="true" >
                    <input type="checkbox" ></input>
                  </td>
                  <td nowrap="true">
                    <Link to={"/admin/pet/"+pet.id}>
                      {pet.name}
                    </Link>
                  </td>
                  <td nowrap="true">{pet.type}</td>
                  {/* <td nowrap="true">{pet.gender}</td> */}
                  <td nowrap="true">{pet.breed}</td>
                  {/* <td nowrap="true">{pet.hair}</td> */}
                  <td nowrap="true">{pet.color}</td>
                  <td nowrap="true">
                    {pet.tag_number ? 
                      <p className="has-text-success">{pet.tag_number}</p> : 
                      <p className="has-text-danger">No tag</p>
                  }</td>
                  <td nowrap="true">{pet.dob}</td>
                  <td nowrap="true">{pet.email}</td>
                </tr>
              )
            }) : 
            <tr>
              <td nowrap="true"><p>No entries</p></td>
            </tr>
          }
        </Table>
      </section>
    </div>
  );
}

export default OwnerSingle;
