import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

// import Tag from '../general/tag';
// import Input from '../general/input';
// import Select from '../general/select';
// import Table from '../general/table';
import ActionBar from '../general/actionBar';

const ScanResult = (props) => {

  const [pet, setPet] = useState();

  useEffect(() => {
    if (props.pet) {
      setPet(props.pet);
    } else {
      axios.get(process.env.REACT_APP_API_URL+'pets/'+props.match.params.id, {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjM4NSwiaXNzIjoiaHR0cHM6Ly9hcGkucm9hbWluZ3RhaWxzLmNvL2FwaS92MS9hdXRoZW50aWNhdGUiLCJpYXQiOjE1OTI5MTAwMzcsImV4cCI6MzYwMTU5MjkxMzYzNywibmJmIjoxNTkyOTEwMDM3LCJqdGkiOiJCbFl6TVNMcUdUekFMUDlYIn0.krPPEod57PVUJRAV3Qf-KaTsGQXK9GqJujt3fH7q6G4",
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((res) => {
        console.log(res.data.data);
        setPet(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }, [])

  return (
    <div>
      <ActionBar
        {...props}
        back={true}
      />

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">{pet?pet.name:""}'s information</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-3">
            <img src={pet?"https://api.roamingtails.co/uploads/profiles/"+pet.image:""} />
          </div>
          <div className="column is-9">
            <div className="columns is-multiline">
              <div className="column is-4">
                <p className="is-size-7">Address 1</p>
                <p className="has-text-weight-bold">{pet && pet.address1 ? pet.address1:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Address 2</p>
                <p className="has-text-weight-bold">{pet && pet.address2 ? pet.address2:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Apt</p>
                <p className="has-text-weight-bold">{pet && pet.apt ? pet.apt:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Owner email</p>
                <p className="has-text-weight-bold">{pet && pet.email ? pet.email:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Owner phone</p>
                <p className="has-text-weight-bold">{pet && pet.phone ? pet.phone:"N/A"}</p>
              </div>

              <div className="column is-4">
              </div>
              
              <div className="column is-4">
                <p className="is-size-7">Name</p>
                <p className="has-text-weight-bold">{pet?pet.name:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Species</p>
                <p className="has-text-weight-bold">{pet && pet.type ? pet.type:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Breed</p>
                <p className="has-text-weight-bold">{pet && pet.breed ? pet.breed:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Hair</p>
                <p className="has-text-weight-bold">{pet && pet.hair ? pet.hair:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Hair color</p>
                <p className="has-text-weight-bold">{pet && pet.color ? pet.color:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Date of birth</p>
                <p className="has-text-weight-bold">{pet && pet.dob ? moment(pet.dob).format("MMM Do YY"):"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Gender</p>
                <p className="has-text-weight-bold">{pet && pet.gender ? pet.gender:"N/A"}</p>
              </div>
              <div className="column is-4">
                <p className="is-size-7">Spayed/Neutered</p>
                <p className="has-text-weight-bold">{pet && pet.spayed ? pet.spayed:"N/A"}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default ScanResult;
