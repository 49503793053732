import React from 'react';
import axios from 'axios';
import Input from '../general/input';
import FaqSingle from '../general/faq-single';

import LostBanner from 'assets/lost-banner.png';

const Scan = (props) => {

  const scanTag = () => {
    axios.get('https://api.roamingtails.co/api/v1/pet/tag/'+document.querySelector('#sTag-number').value, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      // setOwners(res.data.data);
      props.history.push('/user/scan/'+res.data.data.id)
    })
    .catch((err) => {
      console.log(err);
    });
  }
  return (
    <div>
      <div className="banner" style={{backgroundImage: 'url('+ LostBanner +')'}}>
        <section className="hero">
          <div className="hero-body">
           <div className="container">
             <p className="is-size-4 has-text-weight-bold has-text-white">Found a pet? Scan the tag here!</p>
             <p className="has-text-white">Easily alert the owner of the pet's location</p>
           </div>
         </div>
        </section>
      </div>

      <section className="section">
        <div className="columns">
          <div className="column is-4 content">
            <p className="is-size-3 is-marginless">1</p>
            <p className="has-text-weight-bold">Scan the QR Code on the tag or input the tag number below</p>
            <p>Input the 10-digit number that is on the back of the RoamingTail and hit “Find”!</p>
            <Input
              hasAddon={true}
              addonContent={<button onClick={() => scanTag()} className="button is-primary" >Find</button>}
              placeholder="10-digit tag number"
              id="sTag-number"
            />
          </div>

          <div className="column is-4 content">
            <p className="is-size-3 is-marginless">2</p>
            <p className="has-text-weight-bold">View Pet Owner's information</p>
            <p>A page should open up where you will be able to see all the public information that pet and pet owner have, including contact details</p>
          </div>

          <div className="column is-4 content">
            <p className="is-size-3 is-marginless">3</p>
            <p className="has-text-weight-bold">Contact the pet owner</p>
            <p>Contact the pet owner by using the information provided in the pet's profile. Give yourself a pat on the back for helping a furry friend find their way home.</p>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <div className="card">
              <div className="card-content">
                <p className="has-text-weight-bold is-size-4">Tips to take care of a lost pet</p>
              </div>
            </div>
          </div>
        </div>

        <div className="columns is-multiline">
          <div className="column is-6">
            <FaqSingle question="Ask owner via phone if the pet has any special needs" answer="Ask about any special medical needs the pet might have and determine if the pet is allergic to anything."/>
          </div>
          <div className="column is-6">
            <FaqSingle question="Give the pet water" answer="Make sure the pet stays hydrated"/>
          </div>
          <div className="column is-6">
            <FaqSingle question="Comfort the pet" answer="YThis pet has been through a lot and is likely homesick. Make this pet feel at home"/>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Scan;
