import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  Redirect
} from 'react-router-dom';
import './App.scss';

import { AuthProvider, AuthConsumer } from '../utils/AuthContext';
import ProtectedRoute from '../utils/ProtectedRoute';
import AdminRoute from '../utils/AdminRoute';
import ScrollToTop from '../utils/scrollToTop';


// Internal
import InternalLayout from '../layouts/internal';
import DashboardAdmin from '../dashboard/dashboardAdmin';
import PetsInternal from '../petsInternal';
import PetNew from '../pets/petNew';
import Owners from '../owners';
import OwnerNew from '../owners/ownerNew';
import OwnerSingle from '../owners/ownerSingle';
import RabiesInternal from '../rabiesInternal';
import LicensesInternal from '../licensesInternal';
import Stats from '../stats';
import Permissions from '../permissions';


// External
import ExternalLayout from '../layouts/external';
import Dashboard from '../dashboard';
import License from '../license';
import Rabies from '../rabies';
import Pets from '../pets';
import Profile from '../profile';
import PetSingle from '../pets/petSingle';
import Scan from '../scan';
import ScanResult from '../scan/scanResult';
import Map from '../map/map';

// Vet portal
import Vets from '../vets';
import VetOffices from '../vets/vetOffices';
import SendCert from '../vets/sendCert';

import Login from '../account/login';

const App = (props) => {

  return (
    <Router>
      <ScrollToTop />
      <AuthProvider>
        
        <AuthConsumer>
          {({ isAuth, login, logout, user, county }) => (
            <Switch>
              {/* External Routes */}
              <RouteWrapper exact path="/user" component={(props) => <Dashboard county={county}/>} layout={ExternalLayout} />
              <ProtectedRoute path="/user/pets" component={Pets} layout={ExternalLayout} />
              <ProtectedRoute path="/user/pet/:id" component={PetSingle} layout={ExternalLayout} />
              <ProtectedRoute path="/user/profile" component={(props) => <Profile user={user} {...props} />} layout={ExternalLayout} />
              <RouteWrapper path="/user/license" component={(props) => <License county={county} />} layout={ExternalLayout} />

              <RouteWrapper exact path="/user/scan" component={Scan} layout={ExternalLayout} />
              <RouteWrapper path="/user/scan/:id" component={ScanResult} layout={ExternalLayout} />

              <RouteWrapper path="/user/restaurants" component={() => <Map type="restaurants" />} layout={ExternalLayout} />
              <RouteWrapper path="/user/hotels" component={() => <Map type="hotels" />} layout={ExternalLayout} />
              <RouteWrapper path="/user/services" component={() => <Map type="services" />} layout={ExternalLayout} />

              <RouteWrapper path="/user/login" component={Login} layout={ExternalLayout} />

              {/* Internal Routes */}
              <AdminRoute exact path="/admin" component={DashboardAdmin} layout={InternalLayout} />
              <AdminRoute path="/admin/rabies" component={RabiesInternal} layout={InternalLayout} />
              <AdminRoute path="/admin/rabiescert" component={SendCert} layout={InternalLayout} />
              <AdminRoute path="/admin/licenses" component={LicensesInternal} layout={InternalLayout} />

              <AdminRoute path="/admin/owners" component={Owners} layout={InternalLayout} />
              <AdminRoute path="/admin/owner/new" component={OwnerNew} layout={InternalLayout} />
              <AdminRoute path="/admin/owner/:id" component={OwnerSingle} layout={InternalLayout} />

              <AdminRoute path="/admin/pets" component={PetsInternal} layout={InternalLayout} />
              <AdminRoute path="/admin/pet/new" component={PetNew} layout={InternalLayout} />
              <AdminRoute path="/admin/pet/:id" component={PetSingle} layout={InternalLayout} />
              <AdminRoute path="/admin/map" component={() => <Map type="owners" />} layout={InternalLayout} />
              <AdminRoute path="/admin/stats" component={Stats} layout={InternalLayout} />
              <AdminRoute path="/admin/permissions" component={Permissions} layout={InternalLayout} />

              <Route render={(props)=> <Redirect to="/user"/>} />
            </Switch>
          )}
        </AuthConsumer>
      <div>
      </div>
      </AuthProvider>
    </Router>
  );
}

const RouteWrapper = ({
  component: Component,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route {...rest} render={(props) =>
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    } />
  );
}


export default App;
