import React from 'react';
import FAQIllustration from 'assets/faq-illustration.svg'
import FaqSingle from '../general/faq-single';

const Faqs = () => {
  return (
    <section className="section">
      <div className="columns is-multiline is-vcentered">
        <div className="column is-3 has-text-centered">
          <img src={FAQIllustration} alt="Person greeting dog" />
        </div>
        <div className="column is-7">
          <p className="is-size-4 has-text-weight-bold">Frequently asked questions!</p>
        </div>
      </div>

      <div className="columns is-multiline">
        <div className="column is-6">
          <FaqSingle question="How to do that?" answer="You start by doing this!"/>
        </div>
        <div className="column is-6">
          <FaqSingle question="How to do this?" answer="You start by doing that!"/>
        </div>
        <div className="column is-6">
          <FaqSingle question="How to do that?" answer="You start by doing this and that and this and that and everything!"/>
        </div>
        <div className="column is-6">
          <FaqSingle question="How to do this?" answer="You start by doing that!"/>
        </div>
      </div>
    </section>
  )
}

export default Faqs;
