import React from 'react';
import Input from '../general/input';
import Select from '../general/select';
import ActionBar from '../general/actionBar';

import ProfileData from '../profile/profileData';
import ProfileImage from '../profile/profileImage';

const OwnerNew = (props) => {

  return (
    <div>
      <ActionBar
        {...props}
        back={true}
        rightSide={
          <div className="buttons">
            <button className="button is-text is-small" onClick={() => props.history.goBack()}>Cancel</button>
            <button className="button is-success is-small">Add owner</button>
          </div>
        }
      />
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">Add new owner</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-9">
            <ProfileData user={props.user?props.user:""} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default OwnerNew;
