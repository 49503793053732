import React, { useState, useEffect } from 'react';
import PetPreview from './petPreview';
import axios from 'axios';

const PetsListPreview = (props) => {
  const [pets, setPets] = useState();

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'pets', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      setPets(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, [])


  return (
    <div>
      <div className="columns">
        <div className="column is-12">
          <p className="is-size-4 has-text-weight-bold">My pets</p>
        </div>
      </div>

      <div className="columns is-multiline">
        {/* loop through user's pets, for each pet display a preview */}
        {pets ? pets.map((pet, index) => {
          return (
            <div className="column is-7">
              <PetPreview key={index} pet={pet} />
            </div>
          )
        }) : ""}
      </div>
    </div>
  );
}

export default PetsListPreview;
