import React from 'react';
import { AuthConsumer } from '../utils/AuthContext';
import { Link } from 'react-router-dom';

import Logo from '../general/logo';
import LicensesIcon from 'assets/icons8-renew.svg';
import VetIcon from 'assets/icons8-veterinarian.svg';
import MapIcon from 'assets/icons8-map_marker.svg';
import PawIcon from 'assets/icons8-cat_footprint.svg';
import RabiesIcon from 'assets/icons8-syringe.svg';
import PeopleIcon from 'assets/icons8-multicultural_people.svg';
import StatsIcon from 'assets/icons8-line_chart.svg';
import PermissionsIcon from 'assets/icons8-user_rights.svg';

import InternalNav from '../app/internalNav';

const InternalLayout = ({children}) => {

  const toggleSidebar = () => {
    // console.log(document.querySelector('.is-sidebar'));
    document.querySelector('.is-sidebar').classList.toggle('is-hidden');
    document.querySelector('.is-content').classList.toggle('is-12-desktop');
  }

  const toggleMenu = () => {
    document.querySelector(".navbar-burger").classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }
  return(
    <div>
      <nav className="navbar is-hidden-desktop" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/admin">
              <Logo />
            </Link>

            <p role="button" className="navbar-burger burger" onClick={() => toggleMenu()}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </p>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start is-hidden-desktop">
            <ul className="menu-list">
              <Link to="/admin/pets" className="menu-subtitle">
                <img src={PawIcon} height="40" alt="Licenses"></img>
                Pets & Licenses
              </Link>
              <Link to="/admin/owners" className="menu-subtitle">
                <img src={PeopleIcon} height="40" alt="Group of owners"></img>
                Owners
              </Link>
              <Link to="/admin/rabies" className="menu-subtitle">
                <img src={RabiesIcon} height="40" alt="Rabies"></img>
                Rabies
              </Link>
              <Link to="/admin/map" className="menu-subtitle">
                <img src={MapIcon} height="40" alt="Map with marker"></img>
                Map
              </Link>
              {/* <Link to="/admin/vets" className="menu-subtitle">
                <img src={VetIcon} height="40" alt="Paw with medical sign"></img>
                Veterinarians
              </Link> */}

              <hr className="separator" />

              {/* <Link to="/admin/stats" className="menu-subtitle">
                <img src={StatsIcon} height="40" alt="Paw with medical sign"></img>
                Statistics
              </Link> */}

              <Link to="/admin/permissions" className="menu-subtitle">
                <img src={PermissionsIcon} height="40" alt="Man with key"></img>
                Users and Permissions
              </Link>
            </ul>
            </div>
            {/* <div className="navbar-end">
              <div className="navbar-item">
                
              </div>
            </div> */}
          </div>
        </nav>
      <div className="columns is-paddingless is-marginless">
        <div className="column is-3 is-sidebar is-hidden-touch is-paddingless">
          <InternalNav toggleSidebar={() => toggleSidebar()}/>
        </div>
        <AuthConsumer>
          {({ isAuth, login, logout, user, county }) => (
            <div className="column is-content is-9-desktop is-12-touch is-paddingless" style={{paddingLeft: "0px"}}>
              {children}
            </div>
          )}
        </AuthConsumer>
      </div>
    </div>
  )
}
export default InternalLayout;
