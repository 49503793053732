import React from 'react';
import { Link } from 'react-router-dom';

import Tag from '../general/tag';
import moment from 'moment';

const PetPreview = (props) => {

  const pet = props.pet;

  return (
    <Link to={"/user/pet/"+pet.id} className="card is-horizontal is-flex">
      <div className="card-image" style={{backgroundImage: 'url(https://api.roamingtails.co/uploads/profiles/' + pet.image + ')'}}>
      </div>
      <div className="card-content">
        <p><span className="has-text-weight-bold is-size-4">{pet.name}</span> #{pet.tag_number}</p>
        <div className="field">
          <p>{pet.breed}, {moment().from(pet.dob, true)} old</p>
        </div>
        <div className="field is-grouped is-grouped-multiline">
          {pet && moment(pet.created_at).add(1, 'y').isAfter(moment(), '3 months') ? 
            (moment().isAfter(moment(pet.created_at).add(1, 'y')) ? 
              <Tag type="danger">
                <p>License expired</p>
              </Tag> : 
              <Tag type="warning">
                <p>License expiring soon</p>
              </Tag>) 
            : 
            <Tag type="success">
              <p>License active</p>
            </Tag>
          }
          {/* {pet && pet.tag_number ? 
            <Tag type="success">
              <p>License active</p>
            </Tag> : ""
          } */}
          <Tag type="danger">
            <p>No rabies certificate</p>
          </Tag>
        </div>
      </div>
    </Link>
  );
}

export default PetPreview;
