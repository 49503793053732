import React from 'react';
import { AuthConsumer } from '../utils/AuthContext';
import { Link } from 'react-router-dom';

import Logo from '../general/logo';
import LicensesIcon from 'assets/icons8-renew.svg';
import VetIcon from 'assets/icons8-veterinarian.svg';
import MapIcon from 'assets/icons8-map_marker.svg';
import PawIcon from 'assets/icons8-cat_footprint.svg';
import RabiesIcon from 'assets/icons8-syringe.svg';
import PeopleIcon from 'assets/icons8-multicultural_people.svg';
import StatsIcon from 'assets/icons8-line_chart.svg';
import PermissionsIcon from 'assets/icons8-user_rights.svg';

const InternalNav = (props) => {

  return (
    <AuthConsumer>
      {({ isAuth, login, logout, user }) => (
        <div>
          <aside className="menu">
            <div className="navbar-brand">
              <Link className="navbar-item" to="/admin">
                <Logo />
              </Link>
            </div>

            <ul className="menu-list">
              <Link to="/admin/pets" className="menu-subtitle">
                <img src={PawIcon} height="40" alt="Licenses"></img>
                Pets & Licenses
              </Link>
              <Link to="/admin/owners" className="menu-subtitle">
                <img src={PeopleIcon} height="40" alt="Group of owners"></img>
                Owners
              </Link>
              <Link to="/admin/rabies" className="menu-subtitle">
                <img src={RabiesIcon} height="40" alt="Rabies"></img>
                Rabies
              </Link>
              <Link to="/admin/map" className="menu-subtitle">
                <img src={MapIcon} height="40" alt="Map with marker"></img>
                Map
              </Link>
              {/* <Link to="/admin/rabiescert" className="menu-subtitle">
                <img src={VetIcon} height="40" alt="Paw with medical sign"></img>
                Send rabies
              </Link> */}

              <hr className="separator" />

              {/* <Link to="/admin/stats" className="menu-subtitle">
                <img src={StatsIcon} height="40" alt="Paw with medical sign"></img>
                Statistics
              </Link> */}

              <Link to="/admin/permissions" className="menu-subtitle">
                <img src={PermissionsIcon} height="40" alt="Man with key"></img>
                Users and permissions
              </Link>
            </ul>

            <ul className="menu-list">
              <li>
                {isAuth?
                  <a onClick={(e) => logout(e)}>Logout</a>
                :<Link to="/user/login">Login</Link>}
              </li>
            </ul>
          </aside>
        </div>
      )}
    </AuthConsumer>
  );
}

export default InternalNav;
