import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

import Table from '../general/table';
import ActionBar from '../general/actionBar';
import Input from '../general/input';
import Dropdown from '../general/dropdown';
import TitleBanner from '../general/titleBanner';

import SearchIcon from 'assets/icons8-search.svg';
import CatIllustration from 'assets/cat-illustration.svg';
import DogIllustration from 'assets/dog-illustration.svg';


const PetsInternal = (props) => {

  const [pets, setPets] = useState();
  const [entriesNo, setEntriesNo] = useState(50);
  const [filterStr, setFilterStr] = useState('');

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'pets', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
      setPets(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const selectRow = (e) => {
    console.log(e.target);
    e.target.closest('tr').classList.toggle('is-selected');
  }

  return (
    <div>
      <ActionBar
        leftSide={
        <div className="field has-addons">
          <div className="control">
            <a className="button is-static">
              <img src={SearchIcon} height="16" className="is-icon is-16"></img>
            </a>
          </div>
          <div className="control is-expanded">
            <input className="input" type="text" placeholder="Pet name or tag number" onChange={(e) => setFilterStr(e.target.value)} />
          </div>
        </div> }
        rightSide={
          <button className="button is-small is-white">
            <span>Filter</span>
            <span className="icon is-small">
              <svg className="is-16" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50"><path d="M 3.8125 2 C 3.335938 2.089844 2.992188 2.511719 3 3 L 3 6 C 3.003906 6.257813 3.101563 6.503906 3.28125 6.6875 L 19 23.375 L 19 41 C 19.007813 41.347656 19.199219 41.667969 19.5 41.84375 L 29.5 47.84375 C 29.804688 48.019531 30.183594 48.023438 30.492188 47.847656 C 30.796875 47.675781 30.992188 47.351563 31 47 L 31 23.375 L 46.5625 6.84375 C 46.574219 6.832031 46.582031 6.824219 46.59375 6.8125 L 46.71875 6.6875 C 46.765625 6.640625 46.808594 6.585938 46.84375 6.53125 C 46.867188 6.511719 46.886719 6.492188 46.90625 6.46875 C 46.964844 6.339844 46.996094 6.203125 47 6.0625 C 47 6.042969 47 6.019531 47 6 C 47.003906 5.949219 47.003906 5.894531 47 5.84375 L 47 3 C 47 2.449219 46.550781 2 46 2 L 4 2 C 3.96875 2 3.9375 2 3.90625 2 C 3.875 2 3.84375 2 3.8125 2 Z M 5 4 L 45 4 L 45 5.625 L 29.5625 22 L 20.4375 22 L 5 5.625 Z M 21 24 L 29 24 L 29 45.25 L 21 40.46875 Z"></path></svg>
            </span>
          </button>
        }
      />
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <TitleBanner
              leftImg={CatIllustration}
              rightImg={DogIllustration}
              title="Pets"
              subtitle={(pets?pets.length:"0") + " pets found"}
            />
          </div>
        </div>
      </section>

      <section className="section">
        <div className="level is-mobile">
          <div className="level-left">
            <Dropdown name="Actions">
              <a href="#" className="dropdown-item">
                Delete
              </a>
              <a href="#" className="dropdown-item">
                Send
              </a>
            </Dropdown>
          </div>
          <div className="level-right">
            <Link to="/admin/pet/new" className="button is-primary">+ Add pet</Link>
          </div>
        </div>

        <div className="columns">
          <div className="column is-12">
            <Table thTitles={["Name", "Type", "Breed",  "Color", "Tag #", "Date of birth", "Email"]}>
              {pets?pets
                .filter((pet) => {
                  if (JSON.stringify(pet).toLowerCase().includes(filterStr.toLowerCase())) {
                    return pet;
                  }
                })
                .slice(0, entriesNo)
                .map((pet, index) => {
                  return (
                    <tr key={index}>
                      <td nowrap="true" >
                        <input type="checkbox" onChange={(e) => selectRow(e)}></input>
                      </td>
                      <td nowrap="true">
                        <Link to={"/admin/pet/"+pet.id}>
                          {pet.name}
                        </Link>
                      </td>
                      <td nowrap="true">{pet.type}</td>
                      {/* <td nowrap="true">{pet.gender}</td> */}
                      <td nowrap="true">{pet.breed}</td>
                      {/* <td nowrap="true">{pet.hair}</td> */}
                      <td nowrap="true">{pet.color}</td>
                      <td nowrap="true">
                        {pet.tag_number ? 
                          <p className="has-text-success">{pet.tag_number}</p> : 
                          <p className="has-text-danger">No tag</p>
                      }</td>
                      <td nowrap="true">{pet.dob}</td>
                      <td nowrap="true">{pet.email}</td>
                    </tr>
                  )
                }) : 
                <tr>
                  <td nowrap="true">
                  </td>
                  <td nowrap="true">
                    <progress className="progress is-small" max="100">15%</progress>
                  </td>
                </tr>
              }
            </Table>
            <button className="button is-primary" onClick={() => setEntriesNo(entriesNo + 50)}>Load more...</button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PetsInternal;
