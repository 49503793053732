import React, { useState } from 'react';
import FoodImg from 'assets/food.jpg';
import NoProfile from 'assets/noprofile.svg';
import './profileImage.scss';

const ProfileImage = (props) => {
  const [change, setChange] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <div>
      <label className="label">Profile image</label>
      <div className="card profile-image" onMouseEnter={() => setChange(true)} onMouseLeave={() => setChange(false)}>
        <img src={props.image?"https://api.roamingtails.co/uploads/profiles/"+props.image:NoProfile} />
        {change ?
          <div className="profile-image__upload has-background-dark" onClick={() => setModal(true)}>
            <a className="has-text-white">{props.image?"Add image":"Change image"}</a>
          </div> : ""
        }
      </div>

      {modal ?
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setModal(false)}></div>
          <div className="modal-content">
            <div className="card">
              <div className="card-content">
                <p>Upload image</p>
              </div>
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setModal(false)}></button>
        </div> :""
      }
    </div>
  )
}

export default ProfileImage;
