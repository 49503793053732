import React from 'react';
import PetsListPreview from './petsListPreview'

const Pets = (props) => {

  return (
    <div>
      <section className="section">
        <PetsListPreview />
      </section>
    </div>
  );
}

export default Pets;
