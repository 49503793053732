import React from 'react';
import Input from '../general/input';
import Select from '../general/select';
import ActionBar from '../general/actionBar';
import axios from 'axios';

import ProfileData from './profileData';
import ProfileImage from './profileImage';

const Profile = (props) => {

  const save = () => {
    let data = {
      id: props.user.id,
      first_name: document.getElementById("first_name").value ? document.getElementById("first_name").value : "",
      last_name: document.getElementById("last_name").value ? document.getElementById("last_name").value : "",
      mobile: document.getElementById("mobile").value ? document.getElementById("mobile").value : "",
      phone: document.getElementById("phone").value ? document.getElementById("phone").value : "",
      address1: document.getElementById("address1").value ? document.getElementById("address1").value : "",
      address2: document.getElementById("address2").value ? document.getElementById("address2").value : "",
      apt: document.getElementById("apt").value ? document.getElementById("apt").value : "",
      city: document.getElementById("city").value ? document.getElementById("city").value : "",
      zipcode: document.getElementById("zip").value ? document.getElementById("zip").value : "",
      state: document.getElementById("state").value ? document.getElementById("state").value : "",
      temp_address1: "",
      temp_address2: "",
      temp_address_apt: "",
      temp_address_zipcode: "",
      temp_address_state: "",
      temp_address_country_code: "",
      temp_address_first_name: "",
      temp_address_last_name: "",
      temp_address_phone: "",
      temp_address_cell: "",
      picture: ""
    }

    console.log(data)

    axios.post(process.env.REACT_APP_API_URL+'user_update/'+props.user.id, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
    .then((res) => {
      console.log(res.data.data);
    })
    .catch((err) => {
      console.log(err);
    });
  }


  return (
    <div>
      <ActionBar
        {...props}
        rightSide={
          <div className="buttons">

            <button className="button is-success is-small" onClick={() => save()}>Save</button>
          </div>
        }

      />
      <section className="section">
        <div className="columns">
          <div className="column is-12">
            <p className="has-text-weight-bold is-size-4">{props.user.first_name}'s information</p>
          </div>
        </div>

        <div className="columns">
          <div className="column is-3">
            <ProfileImage image={props.user.picture} />
          </div>
          <div className="column is-9">
            <ProfileData user={props.user} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Profile;
